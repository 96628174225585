<!--订单列表-->
<template>
  <div class="account-model">
    <div class="global-header">
      <el-form inline :model="filterInfo">
        <el-form-item label="借款订单编号">
          <el-input
              v-model="filterInfo.loanOrderNo"
              placeholder="借款订单编号"
              clearable
          />
        </el-form-item>
        <el-form-item label="还款单号">
          <el-input
              v-model="filterInfo.repayOrderNo"
              placeholder="还款单号"
              clearable
          />
        </el-form-item>
        <el-form-item label="支付通道流水号">
          <el-input
              v-model="filterInfo.upsChannelOrderNo"
              placeholder="支付通道流水号"
              clearable
          />
        </el-form-item>
        <el-form-item label="">
          <el-button
              :loading="tableLoad"
              type="primary"
              icon="el-icon-search"
              @click="search({ pageNum: 1, pageSize: pageInfo.pageSize })"
          >搜 索
          </el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="global-view-table">
      <div class="table-header">
        <el-radio-group v-model="filterInfo.repayStatus" @change="changeTab">
          <el-radio-button label="null">全部</el-radio-button>
          <el-radio-button :label="item.value" v-for="(item,i) in stateList" :key="i">{{ item.label }}
          </el-radio-button>

        </el-radio-group>
      </div>

      <el-table v-loading="tableLoad" border :data="tableData">
        <el-table-column prop="repayOrderNo" label="还款单号" width="190" fixed="left"/>
        <el-table-column prop="upsChannelOrderNo" label="支付通道流水号" width="190"/>
        <el-table-column prop="loanOrderNo" label="借款订单号" width="190"/>
        <el-table-column prop="upsChannelCode" label="支付通道标识" width="100"/>

        <el-table-column prop="repayTotalAmount" label="本次还款总金额" width="110"/>
        <el-table-column prop="repayAmount" label="本次还款本金" width="100"/>
        <el-table-column prop="repayOverdueAmount" label="本次还款还掉的逾期费" width="150"/>
        <el-table-column prop="repayPoundage" label="本次还掉的服务费" width="120"/>
        <el-table-column prop="repayInterest" label="本次还款还掉的利息" width="130"/>
        <el-table-column prop="repayAccountNo" label="还款账户" width="130"/>
        <el-table-column prop="userId" label="用戶ID" width="80">
          <template slot-scope="{row}">
            <span style="cursor: pointer;color: #409EFF;" @click="toUser(row.userId)">{{ row.userId }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="failReason" label="还款失败原因" width="120" show-overflow-tooltip/>
        <el-table-column prop="notifyTime" label="还款结果响应时间" width="145"/>
        <el-table-column prop="repaySource" label="还款来源" width="100" fixed="right">
          <template slot-scope="{row}">
            <span v-if="row.repaySource == 0">用户主动还款</span>
            <span v-if="row.repaySource == 1">系统自动扣款</span>
            <span v-if="row.repaySource == 2">贷后减免</span>
          </template>
        </el-table-column>
        <el-table-column prop="repayType" label="还款类型" width="100" fixed="right">
          <template slot="header">
            <span>还款类型</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">说明：<br> 还款日正常还当期的为【正常还款】<br>非还款日提前还当期的为【当期结清】<br>
                逾期还款是按照当期全部金额做试算
              </div>
              <i class="el-icon-warning" style="color: #E6A23C;font-size: 14px;margin-left: 5px;"></i>
            </el-tooltip>
          </template>
          <template slot-scope="{row}">
            <span v-if="row.repayType == 1">全部结清</span>
            <span v-if="row.repayType == 2">正常还款</span>
            <span v-if="row.repayType == 3">当期结清</span>
            <span v-if="row.repayType == 4">逾期还款</span>

          </template>
        </el-table-column>
        <el-table-column prop="repayStatus" label="还款状态" width="100" fixed="right">
          <template slot-scope="{row}">
            <el-tag type="primary" v-if="row.repayStatus == 2">还款中</el-tag>
            <el-tag type="success" v-if="row.repayStatus == 1">还款成功</el-tag>
            <el-tag type="danger" v-if="row.repayStatus == 3">还款失败</el-tag>
          </template>
        </el-table-column>
      </el-table>

      <GPagination
          :total="total"
          :current-page="pageInfo.pageNum"
          :size="pageInfo.pageSize"
          @change="changePage"
      />
    </div>


    <el-dialog title="失败原因" :visible.sync="dialogFormVisible" center="">
      <div style="max-height: 500px;overflow-y: auto;">
        <!--<el-card style="margin-bottom: 10px;" shadow="never">-->
        <el-descriptions class="margin-top" :column="1">
          <!--<el-descriptions-item label="打款编号">kooriookami</el-descriptions-item>-->
          <!--<el-descriptions-item label="打款时间">18100000000</el-descriptions-item>-->
          <el-descriptions-item label="失败原因">{{ failReason }}</el-descriptions-item>
        </el-descriptions>
        <!--</el-card>-->
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogFormVisible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getRepayPage
} from "@/api/business";
import {filterFormat} from "@/utils/tools";
import {DatePicker} from "element-ui";

import GPagination from "@/components/GPagination";

export default {
  name: "account-list",
  components: {
    GPagination,
    DatePicker
  },
  data() {
    return {
      channelSource: [],
      filterInfo: {
        loanOrderNo: "",
        repayOrderNo: "",
        upsChannelOrderNo: "",
        repayStatus: null
      },

      tableLoad: false,
      tableData: [],
      total: 0,
      pageInfo: {
        pageNum: 1,
        pageSize: 20,
      },
      dialogFormVisible: false,
      failReason: null,
      stateList: [{
        label: '还款成功',
        value: 1
      }, {
        label: '还款中',
        value: 2
      }, {
        label: '还款失败',
        value: 3
      }]
    };
  },
  created() {
    this.search(this.pageInfo);
  },
  methods: {
    findState(val) {
      return this.stateList.find(item => item.value == val)
    },

    toUser(id) {
      this.$router.push({path: '/userDetail', query: {id: id}})
    },
    changeTab(val) {
      this.filterInfo.state = val;
      this.search({pageNum: 1, pageSize: this.pageInfo.pageSize})
    },
    changePage(page) {
      this.search(page);
    },
    toDetail(row) {
      this.$router.push({path: '/orderDetail', query: {id: row.id, loanOrderNo: row.loanOrderNo}})
    },

    changeTime(time) {
      if (time && time.length) {
        this.filterInfo.startDate = `${time[0]} 00:00`;
        this.filterInfo.endDate = `${time[1]} 23:59`;
      } else {
        this.filterInfo.startDate = "";
        this.filterInfo.endDate = "";
      }
    },

    search(page) {
      this.pageInfo = page;
      this.tableLoad = true;
      getRepayPage({
        ...this.pageInfo,
        ...filterFormat(this.filterInfo),
      })
          .then((res) => {
            const {total, records} = res.data;
            this.tableData = records;
            this.total = total;
            this.tableLoad = false;
          })
          .catch(() => {
            this.tableLoad = false;
          });
    }
  },
};
</script>

<style lang="scss">
.account-model {
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow-y: auto;

  .global-view-table {
    height: auto;
    margin-top: 16px;
    padding: 16px;
  }

  .role-tag {
    margin-right: 8px;
  }
}

.account-dialog {
  .el-dialog__body {
    padding: 24px 24px 0;
  }
}
</style>
